for (let i = 0, link = document.querySelectorAll('a[href="#"]'); i < link.length; i++) {
    link[i].href = 'https://vavadaskv1.com/';
    link[i].target = '_blank';
    link[i].rel = 'nofollow';
}
for (let i = 0, link = document.querySelectorAll('a.app'); i < link.length; i++) {
    link[i].href = 'https://vavada-pwa.storage.googleapis.com/install.html';
    link[i].target = '_blank';
    link[i].rel = 'nofollow';
}
