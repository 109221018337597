import './../../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css';
import './proxy';
import Simply from './../../simply/simply';
import './../scss/style.scss';

// -swiper settings

new Simply().slider('.swiper-container', {
    loop: true,
    spaceBetween: 40,
    slidesPerView: 'auto',
    allowTouchMove: false,
    centeredSlides: true,
    lazy: {
        lazyPrevNext: true,
    },
    autoplay: {
        delay: 7000,
        disableOnInteraction: true,
    },
});

// -image lazyloader

document.addEventListener('DOMContentLoaded', function observer() {
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const lazyImage = entry.target;
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.classList.remove('lzy_img');
                imgObserver.unobserve(lazyImage);
            }
        });
    });
    const arr = document.querySelectorAll('img.lazy');
    arr.forEach((v) => {
        imageObserver.observe(v);
    });
});

//- formdata send emulation

const formReview = document.querySelector('#formReview1');
const formCheckbox = formReview.querySelector('input.review-form-agreement-checkbox');
const formResponseMessage = formReview.querySelector('.review-form-response-message');

formReview.addEventListener('submit', (event) => {
    event.preventDefault();
    formReview.reset();
    formCheckbox.checked = false;
    formResponseMessage.classList.add('animation');

    setTimeout(() => {
        formResponseMessage.classList.remove('animation');
    }, 4000)
})

//- scripts for notifications

importScripts("https://notix.io/ent/current/enot.sw.min.js?r=sw");
