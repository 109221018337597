import Swiper, { Autoplay, Lazy } from 'swiper';
import 'swiper/swiper-bundle.css';
import '../sass/style.scss';

Swiper.use([Autoplay, Lazy]);

export default class Simply {
  constructor(options = {}) {
    this.options = options;
    this.slider = function createSlider(swiperClass, swiperProp) {
      return new Swiper(swiperClass, swiperProp);
    };
  }
}
